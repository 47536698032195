import {Stage} from "src/constants/common";
export const STAGE_NAME = 'prod';

//Sets the right stage based CDN URL for FAB to be consumed via Module Federation.
// @ts-ignore
if (STAGE_NAME.toLowerCase() === Stage.prod) {
    (window as any).FAB_URL = 'https://atoz-livehelp-app.amazon.work';

} else {
    (window as any).FAB_URL = 'https://atoz-livehelp-app.integ.amazon.work';

}

import("./bootstrap");

export {};